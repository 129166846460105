/* eslint-disable prettier/prettier */
import axios from 'axios'
import { globalstate$ } from '../smf-ui-util-app'
const API_URL = 'https://' + process.env.BASE_API_URL
/**
 * This method is responsible to be served as the interceptor of the axios
 * and responsible to inject the token in the authorization header
 * from the global state which will be require for authentication
 *
 * @author Amlan Saha
 */
const setupAxiosInterceptors = (axiosInstance) => {
   axiosInstance.interceptors.request.use((request) => {
      if (globalstate$.value.sessionToken) {
         request.headers.Authorization = globalstate$.value.sessionToken
      }
      return request
   })
   // response interceptor
   axiosInstance.interceptors.response.use(
      (response) => {
         if (response.status != 200) {
            handleAPIErrors(response.status, response)
         }
         return response
      },
      (error) => {
         // 'unknown-error' will be sent if there is no error response.
         const errorCode = error.response
            ? error.response.status
            : 'unknown-error'
         handleAPIErrors(errorCode, error)
         return Promise.reject(error)
      }
   )
}

const handleAPIErrors = (errorCode, errorResponse) => {
   const url = errorResponse?.config?.url ?? ''
   console.error(`API | Error Code: ${errorCode}- ${url}`, errorResponse)
   if (globalstate$.value.userData) {
      // Moving 'unknown-error' out of array to disable logout. Had this as a workaround as the interceptor is not able to capture response for network error happned due to invalid or expired token.
      const loginRedirectErrorCodes = [403, 401]
      if (loginRedirectErrorCodes.includes(errorCode)) {
         // set the global sessionToken and isLoggedInUser to falsy
         globalstate$.next({
            ...globalstate$.value,
            sessionToken: '',
            isLoggedInUser: false,
         })
         console.error('API auth error, logging out')
         window.location = '/logout'
      }
   }
}
/**
 * This method is responsible to call the setupAxiosInterceptors method
 * internally to set the interceptor and then return an instance of the
 * axios for further api communication
 *
 * @author Amlan Saha
 */
const setupAxiosInstance = () => {
   const axiosInstance = axios.create({
      baseURL: API_URL,
      headers: {},
   })
   setupAxiosInterceptors(axiosInstance)
   return axiosInstance
}
// exporting the Axios Instance
export const instance = setupAxiosInstance()
// move to constants
// should not be part of helper class
//Author of note : Amlan Saha
export const urlEndpoints = {
   AUTH_USER: '/authorizer/user',
   AUTH_TOKEN: '/authorizer/tokens',

   COMPANY: '/globe/company',
   ORGANIZATION: '/globe/organization',
   ENTITIES: '/globe/entities',
   SAVE_LOGS: '/logger/saveLogs',

   USER: '/accessConfig/user',
   ROLE: '/accessConfig/role?roleId=',
   ROLE_NAME: '/accessConfig/role?roleName=',
   ROLE_CREATE: '/accessConfig/role',
   DROP_DOWN: '/accessConfig/roleConfigurationValues',
   ROLES: '/accessConfig/roles',
   BUSINESS_UNIT: '/globe/businessunit',
   LEVEL_ROLE: '/accessConfig/level/role',
   PLANTS: '/globe/entity/plants',
   STATES: '/globe/states',
   IMAGE: '/globe/image/plant',
   ACTUALS: '/factoryhealthconfigview/actuals',
   TARGETS_STATUS: '/factoryhealthconfigview/targets/status',
   TARGETS_VALUE_HIERARCHY: '/factoryhealthconfigview/targets/hierarchy',
   TARGETS_DOWNLOAD: '/factoryhealthconfigview/targets/download',
   TARGETS_UPLOAD: '/factoryhealthconfigview/targets/initiate-upload',
   TARGETS_VALIDATE: '/factoryhealthconfigview/targets/validate-upload',
   THROUGHPUT_CURRENT_STATUS: '/factoryhealthdashboardview/d2d/throughput',
   WIP_CURRENT_STATUS: '/factoryhealthdashboardview/d2d/wip',
   DEVIATION_THRESHOLDS: 'factoryhealthconfigview/deviation',
   HEADER_DETAILS_CURRENT_STATUS:
      '/factoryhealthdashboardview/d2d/getHeaderDetails',
   CONFIG_STATUS: '/factorygeneral/status',
   EQUIPMENT_INTELLIGENCE: '/factorygeneral/equipment-intelligence',
   CREW_UPSERT: '/factorygeneral/crew',
   EQUIPMENT_INTELLIGENCE_GET_PRESIGNED_URL:
      '/factorygeneral/initiateFileUpload',
   EQUIPMENT_INTELLIGENCE_PROCESS: 'factorygeneral/uploadEquipmentIntelligence',
   EQUIPMENT_INTELLIGENCE_META_DATA: 'factorygeneral/getMetaData',
   SCHEDULE: 'factorygeneral/schedule',
   PLANT_FINANCIALS: 'factorygeneral/financials',
   GENERAL_CONFIG_STATUS: 'factorygeneral/generalStatus',
   ROTATION: 'factorygeneral/rotation',
   EXPORT_WEEKLY_DATA: 'factorygeneral/export-weekly-rotations',
   WEEKLY_ROTATIONS_PROCESS: 'factorygeneral/process-weekly-rotations',
   WEEKLY_ROTATIONS: 'factorygeneral/weekly-rotations',
   DAILY_ROTATIONS: 'factorygeneral/daily-rotations',
   EXPORT_DAILY_DATA: 'factorygeneral/export-daily-rotations',
   FACTORY_CONFIG_DISCARD: 'factorygeneral/discard',
   NEW_GET_SCHEDULE: 'factorygeneral/schedule/v1',
   GET_FACTORY_TIMEZONE: 'factorygeneral/timezone',
   /**
    * Standard Operating Conditions
    */
   SOC_DATA: '/soc/condition-based-monitoring/',
   SOC_UOMS: '/soc/uoms',
   UPDATE_REFRESH_RATE: '/soc/refresh-rate-cbm',
   UPDATE_RISK_CATEGORY_THRESHOLD: '/soc/risk-category',
   UPDATE_CRITICALITY_RATINGS: '/soc/update-criticality-rating',
   DOWNLOAD_CELL_CRITICALITY: '/soc/export-cell-criticality-csv',
   GET_CRITICALITY_PRESIGNED_URL: '/soc/initiate-csv-upload',
   POST_CELL_CRITICALITY_CSV_UPLOAD: '/soc/ingest-cell-criticality',
   DOWNLOAD_ASSET_CRITICALITY: '/soc/export-asset-criticality-csv',
   POST_ASSET_CRITICALITY_CSV_UPLOAD: '/soc/ingest-asset-criticality',
   RECIPE: '/soc/recipe',
   SOC_RECIPE_LIMIT: '/soc/soc-limit-hierarchy',
   SOC_CONFIG_RISK_TYPE: '/soc/risk-types',
   /**
    * Standard Operating Conditions - D2D screens
    */
   WATCHLIST: '/socdashboardview/watchList',
   FILTER_VALUES: 'socdashboardview/getFilterValues',
   OPERATING_CONDITION: '/socdashboardview/operatingConditions',
   SOC_RISK_TYPE: '/socdashboardview/risk-types',
   /**
    * Overall Equipment Effectiveness
    */
   OEE_ACTUALS: '/oeeconfig/actuals',
   OEE_TARGETS_DOWNLOAD: '/oeeconfig/targets/download',
   OEE_TARGETS_UPLOAD: '/oeeconfig/targets/initiate-upload',
   OEE_TARGETS_VALIDATE: '/oeeconfig/targets/validate-upload',

   // ? Equipment constraint configuration endpoints
   GET_CONSTRAINTS_CONFIG_STATUS: '/ecconfigview/getConfigStatusEC',
   GET_CONSTRAINTS_CONFIG_UOM: '/ecconfigview/getUoM',
   GET_CONSTRAINTS_CONFIG_TIME_RANGE: '/ecconfigview/getTimeRange',

   GET_CONSTRAINTS_CONFIG_ACTUALS: '/ecconfigview/getActuals',
   POST_CONSTRAINTS_CONFIG_ACTUALS: '/ecconfigview/updateActuals',

   GET_CONSTRAINTS_CONFIG_TARGETS: '/ecconfigview/getECMetrics',
   POST_CONSTRAINTS_CONFIG_TARGETS: '/ecconfigview/updateTargetMetrics',

   GET_CONSTRAINTS_CONFIG_MISC: '/ecconfigview/getECMetrics',
   POST_CONSTRAINTS_CONFIG_MISC: '/ecconfigview/updateMiscEC',
   GET_UPLOAD_INFO: '/ecconfigview/getUploadInfo',
   GET_FAULT_CODE_FILE_DOWNLOAD: '/ecconfigview/getFaultCodeTemplate',
   POST_UPLOAD_PRESIGNED_URL: '/ecconfigview/initiateFileUpload',

   // ? EC D2D Screen endpoints
   GET_CONSTRAINTS_STATUS_LIST: '/ecview/getMetricType',
   GET_CARD_CONSTRAINTS_DATA: '/ecview/getECCardMetrics',
   GET_CONSTRAINTS_D2D_HEADER_DATA: '/ecview/getHeaderDetails',
   GET_CONSTRAINTS_TABLE_DATA: '/ecview/fetchECData',
   GET_NOTES_DATA: '/ecview/getNotes',
   POST_NOTES_DATA: '/ecview/updateECActions',

   // ? WO Apis
   GET_WORK_ORDER_LIST_DATA: 'mwoview/getWorkOrderList',
   GET_WORK_ORDER_DETAILS_DATA: 'mwoview/getWorkOrderDetails',
   GET_WORK_ORDER_STATUS: 'mwoview/getWorkOrderStatus',
   POST_CREATE_WORK_ORDER: 'mwoview/createWorkOrder',

   // ? view equipment faultcode
   GET_VIEW_EQUIPMENT_FAULT_DATA: '/ecview/getFaultDetails',

   POST_SNOOZE_CONSTRAINTS_DATA: '/ecview/updateECActions',
   POST_REOPEN_SNOOZE_CONSTRAINTS_DATA: '/ecview/updateECActions',

   GET_CONSTRAINTS_CONFIG_SNOOZE_TIME: '/ecconfigview/getECMetrics',

   OEE_TARGETS_HIERARCHY: '/oeeconfig/targets/hierarchy',
   OEE_IDEAL_RUN_RATE_DOWNLOAD: '/oeeconfig/idealRunRate/download',
   OEE_IDEAL_RUN_RATE_UPLOAD: '/oeeconfig/idealRunRate/initiate-upload',
   OEE_IDEAL_RUN_RATE_VALIDATE: '/oeeconfig/idealRunRate/validate-upload',
   OEE_IDEAL_RUN_RATE_HIERARCHY: '/oeeconfig/idealRunRate/hierarchy',
   OEE_TARGETS_AND_IDEAL_RUN_RATE_STATUS: '/oeeconfig/targets/status',
   OEE_DEVIATION_THRESHOLDS: 'oeeconfig/deviation',

   // OEE D2D Screen endpoints
   OEE_LOSS_EARNINGS: '/oeedashboardview/lossearnings',
   OEE_HEADER_DATA: '/oeedashboardview/header-data',
   OEE_HIERARCHY_DATA: '/oeedashboardview/getOEEHierarchyData',

   // Notification Config API Endpoints
   NOTIFICATION_ACTIVE_TRIGGERS: '/notification/triggers/active',
   NOTIFICATION_TIMING: '/notification/timing',
   NOTIFICATION_ROLES: '/notification/roles/plant',
   NOTIFICATION_TRIGGERS_LIST: '/notification/triggers/getalltriggers',
   SAVE_NOTIFICATIONS: '/notification/triggers/savetriggers',
   GET_PLANT_NOTIFICATIONS: '/notification/notifications',
   DISMISS_NOTIFICATION: '/notification/triggers/dismiss',
   NOTIFICATION_UPSERT_USER_ACTION: '/notification/triggers/upsertUserAction',
   NOTIFICATION_OPERATIONAL_AREAS:
      '/notification/triggers/getAllOperationalArea',
   NOTIFICATION_MOCK_EC_NOTIFICATION: '/notification/ecNotification',
   CUSTOM_NOTIFICATION_DETAILS:
      '/notification/config/getCustomNotificationDetails',
   CUSTOM_NOTIFICATION_SETTINGS:
      '/notification/config/getCustomNotificationSettings',
   SAVE_CUSTOM_NOTIFICATION: '/notification/config/addCustomNotification',
   DELETE_CUSTOM_NOTIFICATION: '/notification/config/deleteCustomNotification',

   // Data Reports
   DATA_REPORT_META_DATA: 'datareporting/metaData',
   GENERATE_DATA_REPORT: 'datareporting/generateDataReport',
   FETCH_REPORT_TABLE_DATA: 'datareporting/fetchReportTable',
   DOWNLOAD_DATA_REPORT: 'datareporting/downloadDataReport',

   // Demo Data Simulator Apis
   FETCH_DDS_PLANTS: '/dds/fetchDdsPlants',
   CREATE_DEMO: '/dds/createDemo',
   DEMO_LIST: '/dds/demoList',
   GET_PLANT_HIERARCHY: '/dds/getPlantHierarchy',
   DEMO_DETAILS: '/dds/getPlantsByDemoId',
   GET_FACTORY_HEALTH_DEVIATION: '/dds/factoryHealthDeviation',
   DDS_GET_OEE_THRESHOLD: '/dds/deviation?entityId=',
   SAVE_ALL_EVENTS: '/dds/saveAllEvents',
   DELETE_DEMO: '/dds/deleteDemo',
   SAVE_AS_DEMO: '/dds/saveAsDemo',
   DEMO_STATE_BY_USER: '/dds/getDemoStateByUser',
   SCHEDULER_EVENT: '/dds/schedulerEvent',
   GET_SOC_RECIPES: '/dds/getRecipesByFactoryId',
   GET_TABLE_OF_PARAMETERS: '/dds/getTableOfParameters',
   GET_FAULT_CODES: '/dds/getFaultCodesInfo',
   /**
    * COMMAND CENTER
    */
   CC_GET_WIDGETS: '/cc/getWidgets',
   CC_GET_WIDGET_CONFIGURATIONS: '/cc/getWidgetConfigurationById',
   CC_EQUIMENT_HIERARCHY: 'cc/entityHeirarchy',
   CC_UPDATE_WIDGETS: '/cc/updateWidgets',
   GET_DASHBOARDS_INIT: 'cc/dashboardInfo',
   CREATE_DASHBOARD: 'cc/dashboard',
   CC_APP_SYNC_CONFIG: '/cc/getAppSyncConfig',
   CC_ALL_DASHBOARD: 'cc/dashboard',
   CC_DELETE_DASHBOARD: 'cc/dashboard',
   CC_SCHEDULE: '/cc/schedule',
   CC_DUPLICATE_DASHBOARD: 'cc/duplicate',

   // MDU url endpoints
   GET_MDU_SOURCE_DATA: '/mduconfigview/gatewaySource',
   GET_MDU_GATEWAY_DETAILS: '/mduconfigview/gatewayDetails',
   GET_MDU_GATEWAY_TABLE_DATA: '/mduconfigview/gatewayDetails',
   POST_GATEWAY_EDIT_DATA: '/mduconfigview/updateGateway',
   POST_GATEWAY_ENABLE_DISABLE_DATA: '/mduconfigview/updateGateway',

   /**
    * BI Config
    */
   BIC_GET_BI_TOOLS: '/dlaasService/biTools',
   BIC_GET_CONFIGURED_TOOL: 'dlaasService/configTool',
   BIC_POST_CONFIGURED_TOOL: 'dlaasService/configTool',
   BIC_DELETE_CONFIGURED_TOOL: 'dlaasService/configTool',

   /**
    * Digital Twin Config
    */
   GET_DIGITAL_TWIN_CONFIG: '/digitalTwin/config',
   POST_DIGITAL_TWIN_CONFIG: '/digitalTwin/config',
   DT_INITIATE_FILE_UPLOAD: '/digitalTwin/initiate-upload',
   DT_VALIDATE_FILE_UPLOAD: '/digitalTwin/validate-upload',
   UPDATE_DT_TAGS: '/digitalTwin/addTags',
   GET_DT_TAG_CONFIG: '/digitalTwin/tagConfig',
   GET_DT_TAG_CONFIG_SETTINGS: '/digitalTwin/tagConfigSettings',
   GET_DT_TAG_CONFIG_METRICS_SETTINGS: '/digitalTwin/getMetricsDetails',
   UPDATE_DT_TAG_CONFIG: '/digitalTwin/updateTagConfig',
   GET_DT_USER_GUIDE_PDF: '/digitalTwin/exportUserGuide',

   /**
    * PPE Insights Config
    */
   GET_PPE_INSIGHTS_CONFIG: '/factorygeneral/status',
   POST_PPE_INSIGHTS_CONFIG: '/ppe/updatePPEStatus',

   UPDATE_PM_CONFIG: '/pm/updateConfig',
   UPDATE_SPC_CONFIG: '/smartspc/updateSpcStatus',
   GET_GENCI_CONFIG_STATUS: '/genciconfig/status',
   GET_SINGLEDB_AUTO_PROMPT_CONFIG_STATUS: '/genciconfig/autoSuggestionSingledbStatus',
   GET_VA_AUTO_PROMPT_CONFIG_STATUS: '/genciconfig/autoSuggestionVAStatus',
   GET_GENCI_PRESIGNED_URL: '/genciconfig/uploadConfig',
   UPDATE_GENCI_FILE_CONFIG: '/genciconfig/upsertConfig',
   GET_GENCI_FILE_DATA: '/genciconfig/config',
   GENCI_DATA_SOURCES: '/genciconfig/genCIDataSource',
   UPDATE_YP_CONFIG: '/oeeconfig/updateYieldPredictionConfig',
   GET_DMS_FOLDERS: '/genciconfig/getDmsFolders',
   SAVE_DMS_FOLDERS: '/genciconfig/saveDmsFolders',
   DELETE_DMS_EMBEDDINGS: '/genciconfig/dms/deleteEmbeddings',
   GENCI_FINE_TUNING:'genciconfig/fineTuning',
   UPDATE_VISUAL_INSPECTION_CONFIG: '/visualinspection/updateViStatus',
    
   /**
    * Entity Management
    */
   GET_ENTITY_HIERARCHY: '/factorygeneral/entityHierarchy', 
}

import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
  Typography,
} from '@mui/material';
import SideBar from '../SideBar';
import WhiteDivider from '../WhiteDivider/WhiteDivider';
import checkedTickMark from '../../assets/img/checkedTickMark.svg';
import uncheckedTickMark from '../../assets/img/uncheckedTickMark.svg';
import { useRxjsState } from '../../utils/useRxjsState';
import { getFactoryConfigStatuses } from '../../utils/apiHelper';
import { updateRxjsState } from '../../utils/rxjsStateNext';
import CommonBackdrop from '../CommonBackdrop';
import LoadingIndicator from '../LoadingIndicator';

function PageSideBar({ children }) {
  return (
    <SideBar
      nonToolbar
      modalProps={{
        style: {
          position: 'fixed',
          top: 'unset',
          left: 'unset',
          right: 'unset',
        },
      }}
      paperProps={{
        style: {
          position: 'inherit',
        },
      }}
      // customDrawerClasses={drawerClasses}
    >
      <Box>{children}</Box>
    </SideBar>
  );
}

const SubMenuTypography = styled(Box)(() => ({
  fontSize: '1rem',
  fontWeight: '600',
  marginBottom: '1.344rem',
  lineHeight: '1.362rem',
}));

function SubMenu({ heading, children }) {
  return (
    <Box>
      {heading && (
        <Box sx={{ marginY: 1, marginLeft: '1.25rem' }}>
          <SubMenuTypography key={heading} data-testid="subMenuHeading">
            {heading}
          </SubMenuTypography>
        </Box>
      )}
      {children}
    </Box>
  );
}

const ListItemTypography = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isDisabled',
})(({ theme, isSelected = false, isDisabled = false }) => {
  let borderLeft = `0.25rem solid ${theme.palette.background.configLighterDark}`;
  let background = 'none';
  if (isDisabled) background = theme.palette.background.gunmetalGrey;
  if (isSelected)
    borderLeft = `0.25rem solid ${theme.palette.selected.configSidebarWhite}`;
  return {
    padding: 0,
    marginTop: 6,
    marginBottom: 4,
    borderLeft: borderLeft,
    cursor: 'pointer',
    '&:hover': {
      background: background,
    },
    pointerEvents: `${isDisabled ? 'none' : 'inherit'}`,
  };
});

const ListItemTextTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isDisabled',
})(({ theme, isSelected = false, isDisabled = false }) => {
  let color = theme.palette.text.primary;
  let fontWeight = 'none';
  if (isDisabled) color = theme.palette.background.darkGrey;
  if (isSelected) fontWeight = '700';
  return {
    fontSize: '0.875rem',
    lineHeight: '1.192rem',
    fontWeight: fontWeight,
    color: color,
  };
});

function DefaultMenu({ sections, onMenuItemClick, selectedOption = '' }) {
  const { rxjsState } = useRxjsState();
  const [entityId, setEntityId] = useState(rxjsState.plantId);
  const [configStatusReFetchIntervalMs, setConfigStatusReFetchIntervalMs] =
    React.useState(0);
  const [configStatusReFetchCount, setConfigStatusReFetchCount] =
    React.useState(0);

  useEffect(() => {
    if (rxjsState.plantId) {
      setEntityId(rxjsState.plantId);
    }
  }, [rxjsState.plantId]);

  useEffect(() => {
    if (rxjsState.isEIPorUoMChanged) {
      refetchConfigStatus();
      updateRxjsState({ isEIPorUoMChanged: false });
    }
  }, [refetchConfigStatus, rxjsState.isEIPorUoMChanged]);

  useEffect(() => {
    console.log('configStatusReFetchCount', configStatusReFetchCount);
    if (configStatusReFetchCount >= 10) {
      // reset count and stop refetch of getFactoryConfigStatuses
      setConfigStatusReFetchCount(0);
      setConfigStatusReFetchIntervalMs(0);
    } else if (configStatusReFetchCount != 0) {
      // refetch every second - getFactoryConfigStatuses
      setConfigStatusReFetchIntervalMs(2000);
    }
  }, [configStatusReFetchCount]);

  /**
   * Function to Set a FactoryConfig Status response in RxJs Global state
   * @param {*} response FactoryConfigStatus from API
   */
  function updateConfigStatus(response = {}) {
    const {
      general: factoryGeneral,
      factoryHealth,
      soc,
      equipmentConstraint: constraints,
      oee,
      connectivityTab: connectivity,
      pm,
      sensorBasedPdm,
      spc,
      genci,
      yp,
    } = response;
    updateRxjsState({
      configStatus: {
        ...rxjsState.configStatus,
        factoryGeneral,
        factoryHealth,
        soc,
        constraints,
        oee,
        connectivity,
        pm,
        sensorBasedPdm,
        spc,
        genci,
        yp,
      },
    });
  }

  // FactoryConfigStatus from Global State
  const configStatus = rxjsState?.configStatus || {};

  const { refetch: refetchConfigStatus, isLoading: isStatusLoading } = useQuery(
    ['getFactoryConfigStatuses', entityId],
    async () => {
      const response = await getFactoryConfigStatuses(entityId);
      return response;
    },
    {
      onSuccess: (response) => {
        updateConfigStatus(response);
        setConfigStatusReFetchCount((previous) => ++previous);
      },
      enabled: !!entityId,
      refetchOnWindowFocus: false,
      refetchInterval: configStatusReFetchIntervalMs, // FIXME: Refetch logic to update SOC status. This has to be removed after a tech debt to get config status via websocket.
    }
  );

  return isStatusLoading ? (
    <CommonBackdrop open>
      <LoadingIndicator />
    </CommonBackdrop>
  ) : (
    <List>
      {sections.map(({ heading = '', options }, index) => (
        <Box key={heading}>
          <SubMenu heading={heading}>
            <Box sx={{ padding: '0 0.625rem 0 1.336rem' }}>
              {options.map(
                ({ name, isDisabled, label, key, showConfigStatus }, idx) => (
                  <ListItemTypography
                    disabled={isDisabled}
                    key={`${heading} ${name}`}
                    isSelected={name === selectedOption}
                    isDisabled={isDisabled}
                    value={name}
                    onClick={(e) => onMenuItemClick(e, name)}
                    disableGutters
                    data-testid={`sidebar-menu-item-${idx}`}
                  >
                    <ListItemText
                      sx={{
                        marginRight: '0.5rem',
                        paddingTop: '0.25rem',
                        paddingBottom: '0.25rem',
                        paddingLeft: '0.625rem',
                      }}
                    >
                      <ListItemTextTypography
                        isSelected={name === selectedOption}
                        isDisabled={isDisabled}
                      >
                        {label}
                      </ListItemTextTypography>
                    </ListItemText>
                    {showConfigStatus && (
                      <ListItemIcon sx={{ minWidth: '0px' }}>
                        {configStatus[key] === true ? (
                          <img src={checkedTickMark} alt="checked-tick-mark" />
                        ) : (
                          <img
                            src={uncheckedTickMark}
                            alt="unchecked-tick-mark"
                          />
                        )}
                      </ListItemIcon>
                    )}
                  </ListItemTypography>
                )
              )}
              {index !== sections.length - 1 && (
                <Box marginTop={1} marginBottom={1}>
                  <PageSideBar.Divider variant="fullWidth" />
                </Box>
              )}
            </Box>
          </SubMenu>
        </Box>
      ))}
    </List>
  );
}

PageSideBar.Divider = WhiteDivider;
PageSideBar.SubMenu = SubMenu;
PageSideBar.DefaultMenu = DefaultMenu;

export default PageSideBar;
